import * as React from "react";
import Container from "@mui/material/Container";
import { Box, Stack, Card, CardHeader, CardContent, Button, Link, Typography, Divider } from "@mui/material";
import { List, ListItem, ListItemText } from "@mui/material";

export default function Page() {

  return (

    <Container maxWidth="false">
      <Box
        container
        display={"flex"}
        justifyContent={"center"}
        sx={{
          marginTop: 5,
          // height: "calc(100vh - 128px)",
        }}
      >
        <Stack direction="column" gap={1}>
          <Typography variant="h2">AI Coach Platform Support - Privacy Policy</Typography>
          <Typography variant="h4">Introduction</Typography>
          <Typography>
            [AI Coaching Platform] ("we", "our", or "us") is committed to protecting your privacy.
            This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our iPad application (the "App").
            Please read this policy carefully. If you do not agree with the terms of this Privacy Policy, please do not use the App.
          </Typography>

          <Typography variant="h4">Information We Collect</Typography>
          <Typography variant="h6">Personal Information</Typography>
          <Typography>
            We may collect personal information that you voluntarily provide to us, such as:
            <List>
              <ListItem >
                - Personal Identity QRCode
              </ListItem>
              <ListItem>
                - Student Number
              </ListItem>
            </List>
          </Typography>
          <Typography variant="h6">Usage Data</Typography>
          <Typography>
            We may collect information automatically when you use the App, such as:
            <List>
              <ListItem >
                - Exercise Score
              </ListItem>
              <ListItem >
                - Exercise Duration
              </ListItem>
            </List>
          </Typography>
          <Typography variant="h6">How We Use Your Information</Typography>
          <Typography>
            We use the information we collect for various purposes, including:
            <List>
              <ListItem >
                - To operate and maintain the App
              </ListItem>
              <ListItem >
                - To improve the App and your user experience
              </ListItem>
              <ListItem >
                - To communicate with you, including sending updates and promotional materials
              </ListItem>
              <ListItem >
                - To address technical issues and analyze usage
              </ListItem>
            </List>
          </Typography>

          <Typography variant="h4">Sharing Your Information</Typography>
          <Typography>
            We do not sell or rent your personal information to third parties. We may share your information with:
            <List>
              <ListItem >
                - Service Providers: To assist in operating the App and providing services.
              </ListItem>
              <ListItem >
                - Legal Requirements: If required to do so by law or in response to valid requests by public authorities.
              </ListItem>
            </List>
          </Typography>

          <Typography variant="h4">Data Security</Typography>
          <Typography>
            We use technical and organizational measures to protect your personal information.
            However, no method of transmission over the internet
            or method of electronic storage is 100% secure.
          </Typography>

          <Typography variant="h4">Your Rights</Typography>
          <Typography>
            Depending on your location, you may have the following rights:
            <List>
              <ListItem >
                - Access to your personal information
              </ListItem>
              <ListItem >
                - Correction of any inaccuracies
              </ListItem>
              <ListItem >
                - Deletion of your personal information
              </ListItem>
              <ListItem >
                - Restricting processing of your personal information
              </ListItem>
              <ListItem >
                - Data portability
              </ListItem>
            </List>

            To exercise these rights, please contact us at norris@caretech.com.hk.

          </Typography>


          <Typography variant="h6">
            Thank you for using AI Coaching Platform. Your privacy is important to us.
          </Typography>
        </Stack>
      </Box>

    </Container>
  );

}
