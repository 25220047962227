import * as React from "react";
import Container from "@mui/material/Container";
import { Box, Stack, Card, CardHeader, CardContent, Button, Link, Typography, Divider } from "@mui/material";


export default function Page() {

  return (

    <Container maxWidth="false">
      <Box
        container
        display={"flex"}
        justifyContent={"center"}
        sx={{
          marginTop: 5,
          // height: "calc(100vh - 128px)",
        }}
      >
        <Stack direction="column" gap={1}>
          <Typography variant="h5">AI Coach Platform Support</Typography>
          <Typography>
            Welcome to the AI Coaching Platform Support Page. We're here to assist you with any questions or issues you may encounter.
          </Typography>
          <Typography variant="h5">Contact Us</Typography>
          <Typography>
            If you need help, reach out through the email: norris@cartech.com
            

          </Typography>
        </Stack>
      </Box>

    </Container>
  );

}
